import React, { Component } from 'react';

class Contact extends Component {
   render() {

      if (this.props.data) {
         var name = this.props.data.name;
         var phone = this.props.data.phone;
         var email = this.props.data.email;
         var message = this.props.data.contactmessage;
      }

      return (
         <section id="contact">
            <div className="row section-head">
               <h1></h1 >
               <h2>Contact Me</h2>
               <p className="address">{name}<br /><a href="mailto:oznacargazi@gmail.com">{email}</a></p>
            </div>
         </section>
      );
   }
}

export default Contact;