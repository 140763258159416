import React, { Component } from 'react';

class Resume extends Component {
  render() {

    function CheckLink(url, urlmessage) {
      if (url) {
        var logo = "fa fa-link";
        var style = "resume-url";
        if (urlmessage.toLowerCase().includes("youtube")) {
          var logo = "fa fa-youtube"
          var style = "youtube-url";
        }
        return <a href={url} target="_blank" className={style}><i className={logo} /> {urlmessage}</a>
      }
      return undefined;

    }

    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(function (education) {
        var educationImage = 'images/education/' + education.image;
        return <div className="education-div" key={education.school}>
          <img src={educationImage} className="feature-item education-img"></img>
          <h3>{education.school}</h3>
          <p className="info">{education.degree}<br></br><em className="date">{education.graduated}</em></p>
          <p className="justified">{education.description}</p></div>
      })
      var work = this.props.data.work.map(function (work) {
        var workImage = 'images/work/' + work.image;
        return <div className="work-div" key={work.company}>
          <img src={workImage} className="feature-item work-img"></img>
          <h3>{work.company}</h3>
          <p className="info">{work.title}<br></br><em className="date">{work.years}</em></p>
          <p className="justified">{work.description}</p>
        </div>
      })
      var skills = this.props.data.skills.map(function (skills) {
        var className = 'bar-expand ' + skills.name.toLowerCase();
        return <li key={skills.name}><span style={{ width: skills.level }} className={className}></span><em>{skills.name}</em></li>
      })

      var projectDescription = this.props.data.projectsDescription;
      var projects = this.props.data.projects.map(function (project) {
        var link = CheckLink(project.url, project.urlDescription);
        var projectImage = 'images/portfolio/' + project.image;
        return <div key={project.name} className="columns feature-item">
          <img alt={project.title} src={projectImage} className="project-img" />
          <h5>{project.title}</h5>
          <p className="justified">{project.description}<br />{project.technologies}</p>
          {link}
        </div>
      })

      var hobbiesDescription = this.props.data.hobbiesmessage;

      var hobbies = this.props.data.hobbies.map(function (hobby) {
        var link = CheckLink(hobby.url, hobby.urlmessage)
        var hobbyImage = 'images/hobbies/' + hobby.image;
        return <div key={hobby.name} className="columns feature-item resume-item">

          <img alt={hobby.title} src={hobbyImage} className="hobby-img" />
          <h5>{hobby.title}</h5>
          <p className="justified">{hobby.description}</p>{link}

        </div>
      })
    }

    return (
      <section id="resume">

        <div className="row education">
          <div className="three columns header-col">
            <h1><span>Education</span></h1>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns education-container">
                {education}
              </div>
            </div>
          </div>
        </div>


        <div className="row work">

          <div className="three columns header-col">
            <h1><span>Work</span></h1>
          </div>

          <div className="nine columns main-col work-container">
            {work}
          </div>
        </div>



        <div className="row skill">

          <div className="three columns header-col">
            <h1><span>Skills</span></h1>
          </div>

          <div className="nine columns main-col">

            <p class="sublead center">{skillmessage}
            </p>

            <div className="bars">
              <ul className="skills">
                {skills}
              </ul>
            </div>
          </div>
        </div>

        <div className="row portfolio">

          <div className="three columns header-col">
            <h1><span>Projects</span></h1>
          </div>

          <div>
            <div className="nine columns main-col resume-div-flex"><p className="sublead center">{projectDescription}</p></div>
            <ul className="bgrid-quarters s-bgrid-thirds cf">
              {projects}
            </ul>
          </div>
        </div>

        <div className="row hobbies">

          <div className="three columns header-col">
            <h1><span>Hobbies</span></h1>
          </div>

          <div>
            <div className="nine columns main-col resume-div-flex"><p className="sublead center">{hobbiesDescription}</p></div>
            <ul className="bgrid-quarters s-bgrid-thirds cf">
              {hobbies}
            </ul>
          </div>
        </div>

      </section>
    );
  }
}

export default Resume;
