import React, { Component } from 'react';
import ReactGA from 'react-ga';
import $ from 'jquery';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
import Contact from './Components/Contact';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      foo: 'bar',
      resumeData: {
        "main": {
          "name": "Gazi Oznacar",
          "occupation": "",
          "description": "",
          "image": "ppic.jpg",
          "bio": "I am a software engineer with a great passion for science and technology. I hunger for knowledge and try to turn the information into actions. Due to my father's interest in computers, I was able to spend a considerable amount of time with them since my childhood. This passion of mine carried me through my higher education. Previously, I was working as a Software Engineer in the world's best broadcasting solutions provider, Grass Valley. Due to my interest in the financial markets, I have decided to join MAN Group, the company is ranked one of the top 5 hedge funds in the world and is the world's largest publicly traded hedge fund company.",
          "contactmessage": "",
          "email": "oznacargazi@gmail.com",
          "website": "",
          "resumedownload": "GaziOznacarCV.pdf",
          "social": [
            {
              "name": "linkedin",
              "url": "http://www.linkedin.com/in/oznacargazi",
              "className": "fa fa-linkedin"
            },
            {
              "name": "instagram",
              "url": "http://instagram.com/gazioznacar",
              "className": "fa fa-instagram"
            },
            {
              "name": "github",
              "url": "http://github.com/oznacargazi",
              "className": "fa fa-github"
            },
            {
              "name": "gmail",
              "url": "mailto:oznacargazi@gmail.com",
              "className": "fa fa-envelope"
            }
          ]
        },
        "resume": {
          "skillmessage": "I like learning new technologies and adapting to the latest trends in the industry but these are the technologies that I most commonly use.",
          "education": [
            {
              "school": "University College London",
              "degree": "MSc Software Systems Engineering (Merit)",
              "graduated": "Sep 2018 - Sep 2019",
              "description": "Graduated with Merit, in the course I learnt principles and techniques of validating and verifying software systems including static and dynamic analysis, soundness and completeness of a software system, unit, functional, integration, regression, system and acceptance testing. Additionally, learnt basics of data mining and information retrieval by delivering coursework that included different techniques such as Vector Space Document Retrieval, Probabilistic Document Retrieval, Logistic Regression for text classification.",
              "image": "ucl.png"
            },
            {
              "school": "University Of Hull",
              "degree": "BSc Computer Software Engineering (First Class with Honours)",
              "graduated": "Sep 2014 - June 2018",
              "description": "Graduated with First Class Honours and was awarded Best Honours Stage Academic Performance 2017-18. Covered the advanced methods of software engineering and methods of software project management. Covered the basics of distributed software systems and their architectures such as Service-oriented architecture, APIs, HTTP protocols, communication between components. Further, strengthed objected-oriented programming knowledge and implemented a variety of coursework projects.",
              "image": "uoh2.png"
            }
          ],
          "work": [
            {
              "company": "MAN Group",
              "title": "Associate Software Engineer",
              "years": "May 2022 - Present",
              "description": "Currently, I am placed in a team that is responsible for the end-to-end delivery process of various services which includes requirements gathering through to development, testing, and coordinating deployment to production. I also participate in the planning, design, and delivery of longer-term projects. I also participate in reviewing the code written by my peers and knowledge amongst my peers. The role also involves continuously improving the production components which includes reducing the tech debt, increasing release quality and improving resource utilisation. The technologies used include C# and .NET Framework, MS SQL, MSMQ, Kafka, WPF, Git, PowerShell and React.",
              "image": "mangroup.png"
            },
            {
              "company": "Grass Valley",
              "title": "Graduate Software Engineer - Software Engineer",
              "years": "October 2019 - May 2022",
              "description": "I was working in the team that is responsible for developing a SaaS broadcasting solution for a well-known sports channel that will also be used in the 2021 Summer Olympics. Further, the project was named as AMPP Playout which is the first playout solution built on elastic compute technology. I was responsible for developing and maintaining the integration tests for the software. Additionally, I re-designed and implemented the daily smoke tests for the product. I helped to design, implementing and testing several APIs to be used as micro-services. During my second year in the company, I was promoted to Software Engineer and was placed in a team that mostly focused on the DevOps side of things. The technologies used include .NET Framework, PowerShell, C\#, Jenkins, BitBucket, Azure Repos, AWS, Git, Visual Studio.",
              "image": "gv.png"
            }
          ],
          "skills": [
            {
              "name": "C#",
              "level": "90%"
            },
            {
              "name": ".Net Framework",
              "level": "85%"
            },
            {
              "name": "PowerShell",
              "level": "85%"
            },
            {
              "name": "MS SQL",
              "level": "80%"
            },
            {
              "name": "Jenkins",
              "level": "79%"
            },
            {
              "name": "Python",
              "level": "70%"
            },
            {
              "name": "ReactJS",
              "level": "70%"
            }
          ],
          "projectsDescription": "Here are some projects that I worked on previously.",
          "projects": [
            {
              "title": "Discord Bot",
              "description": "Discord is an application designed for VoIP that enables people to talk to each other. I have developed a Discord Bot to be used in my friend’s server. The bot is capable of processing certain commands given by the user. Bot functionalities include playing songs from YouTube, performing YouTube search and letting the user to choose what to be played from the search and text-to-speech functionality and includes many more functionalities for users.",
              "image": "dc.svg",
              "technologies": "\nTechnologies used: Node.js, AWS, GIT"
            },
            {
              "title": "Automating GitHub Classroom Deployment on Azure",
              "description": "During my Masters I was the team leader of this project with an aim to implement an automated process for the GitHub Classroom deployment on Microsoft Azure. GitHub Classroom is an open-source tool for academicians that can aid with student assignment management.",
              "image": "github.svg",
              "technologies": "\nTechnologies used: Kubernetes, Docker, Bash, Azure DevOps, GitHub"
            },
            {
              "title": "Movement Analytics Platform (MAP)",
              "description": "I and a group of students worked with ARM to extend their proprietary facial recognition cameras to implement a web application that uses this information to visualise human movement in the building. With this, users will be able to tell how crowded a place is, investigate historical movements and predict where people may go next.",
              "image": "arm.svg",
              "technologies": "\nTechnologies used: Python, Django, Azure DevOps, Keras, Javascript, MVC Architecture"
            },
            {
              "title": "Python Coding Style Compliance On Stack Overflow",
              "description": "I and a group of students worked on mining and analysing to determine if the Python code found in StackOverflow compliance with the coding convention standards. We mined and analysed the data from SOTorrent which is an open dataset based on data from the official SO data dump. If you are interested in reading the paper you can find it in the link provided below.",
              "image": "so.svg",
              "url": "http://www0.cs.ucl.ac.uk/staff/j.krinke/publications/msr19.pdf",
              "urlDescription": "Click here for the paper.",
              "technologies": "\nTechnologies used: Python, Google Cloud, R"
            }
          ],
          "hobbiesmessage": "Here are some of the things that I like to do outside of work.",
          "hobbies": [
            {
              "title": "Playing the guitar",
              "description": "I have been playing the guitar for over 10 years now. I am a big fan of creating sounds and making music.",
              "image": "guitar.gif",
              "url": "https://www.youtube.com/channel/UCxqkseDt6s2nJwR3ILXA_Jw",
              "urlmessage": "Check out my YouTube channel."
            },
            {
              "title": "Travelling",
              "description": "Travelling is one of the things that I enjoy most because travelling to different places makes you realize that no matter how much you know, there’s always more to learn.",
              "image": "travel.gif"
            },
            {
              "title": "Video Games",
              "description": "I have been playing video games pretty much since I was born. I owe games a lot for making me who I am today. My favourite video game is The Last Of Us.",
              "image": "game.gif"
            },
            {
              "title": "Reading",
              "description": "I like reading stuff and learning from them. I quite like reading anything that involves Sci-Fi and World History including comic books and mangas.",
              "image": "reading.gif"
            }
          ]
        }
      }
    };

    ReactGA.initialize('UA-110570651-1');
    ReactGA.pageview(window.location.pathname);

  }

  // getResumeData() {
  //   $.ajax({
  //     url: './resumeData.json',
  //     dataType: 'json',
  //     cache: false,
  //     success: function (data) {
  //       this.setState({ resumeData: data });
  //     }.bind(this),
  //     error: function (xhr, status, err) {
  //       console.log(err);
  //       alert(err);
  //     }
  //   });
  // }



  // componentDidMount() {
  //   // this.getResumeData();
  // }

  render() {
    return (
      <div className="App" onContextMenu={(e) => e.preventDefault()}>
        <Header data={this.state.resumeData.main} />
        <About data={this.state.resumeData.main} />
        <Resume data={this.state.resumeData.resume} />
        <Contact data={this.state.resumeData.main} />
        <Footer data={this.state.resumeData.main} />
      </div >
    );
  }
}

export default App;
